var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content-box",style:({
    backgroundImage: 'url(' + _vm.pagebg1 + ')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain'
  })},[_c('div',{staticClass:"info-banner",style:({
      backgroundImage: 'url(' + _vm.bannerImg + ')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    })},[_c('head-main',{staticClass:"no-fixed"})],1),_c('div',{staticClass:"infomation-box pd-5"},[_c('ul',{staticClass:"video-live-list"},_vm._l((_vm.liveList),function(item){return _c('li',{key:item.index},[_c('div',{staticClass:"video-box"},[_c('img',{attrs:{"src":item.img}})]),_vm._m(0,true),_c('div',{staticClass:"live-info"},[_c('div',{staticClass:"live-text"},[_c('h5',[_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(item.isshow),expression:"item.isshow"}],staticClass:"vedio-tbn"},[_vm._v("置顶")]),_vm._v(_vm._s(item.tit))],1),_c('p',[_vm._v(_vm._s(item.con))])]),_c('div',{staticClass:"live-time"},[_c('span',[_vm._v("直播地点："+_vm._s(item.po))]),_c('span',[_vm._v("直播时间："+_vm._s(item.time))])])])])}),0),_c('div',{staticClass:"custon-page"},[_c('el-button',{staticClass:"page-btn"},[_c('span',[_vm._v("首页")])]),_c('el-pagination',{attrs:{"current-page":_vm.currentPage4,"page-sizes":[10, 20],"layout":"prev, pager, next,slot,jumper","pager-count":"7","prev-text":"上一页","next-text":"下一页","total":100}},[_c('el-button',{staticClass:"page-btn"},[_c('span',[_vm._v("尾页")])]),_c('span',{staticClass:"totals"},[_vm._v("第01页/共06页")])],1)],1)]),_c('botto-com',{staticClass:"bottom-white no-fixed"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"live-do"},[_c('span',{staticClass:"video-play"},[_c('i',{staticClass:"line-item first"}),_c('i',{staticClass:"line-item two"}),_c('i',{staticClass:"line-item three"})]),_vm._v(" 正在直播 ")])}]

export { render, staticRenderFns }