<template>
  <div
    class="page-content-box"
    :style="{
      backgroundImage: 'url(' + pagebg1 + ')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain'
    }"
  >
    <div
      class="info-banner"
      :style="{
        backgroundImage: 'url(' + bannerImg + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }"
    >
      <head-main class="no-fixed"></head-main>
    </div>
    <div class="infomation-box pd-5">
      <ul class="video-live-list">
        <li v-for="item in liveList" :key="item.index">
          <div class="video-box">
            <img :src="item.img" />
          </div>
          <span class="live-do">
            <span class="video-play">
              <i class="line-item first"></i>
              <i class="line-item two"></i>
              <i class="line-item three"></i>
            </span>
            正在直播
          </span>
          <div class="live-info">
            <div class="live-text">
              <h5><el-button v-show="item.isshow" class="vedio-tbn">置顶</el-button>{{ item.tit }}</h5>
              <p>{{ item.con }}</p>
            </div>
            <div class="live-time">
              <span>直播地点：{{ item.po }}</span>
              <span>直播时间：{{ item.time }}</span>
            </div>
          </div>
        </li>
      </ul>

      <div class="custon-page">
        <el-button class="page-btn"><span>首页</span></el-button>
        <el-pagination
          :current-page="currentPage4"
          :page-sizes="[10, 20]"
          layout="prev, pager, next,slot,jumper"
          pager-count="7"
          prev-text="上一页"
          next-text="下一页"
          :total="100"
        >
          <el-button class="page-btn"><span>尾页</span></el-button>
          <span class="totals">第01页/共06页</span>
        </el-pagination>
      </div>
    </div>

    <botto-com class="bottom-white no-fixed"></botto-com>
  </div>
</template>
<script>
import HeadMain from '../../components/head'
import BottoCom from '../../components/bottom'
export default {
  components: {
    HeadMain,
    BottoCom
  },
  data() {
    return {
      currentPage4: 4,
      bannerImg: require('../../assets/images/live-banner.jpg'),
      pagebg1: require('../../assets/images/page-bg1.jpg'),
      liveList: [
        {
          img: require('../../assets/images/video-bg-do.jpg'),
          isshow: true,
          tit: '这里是直播标题介绍',
          con: '这里是直播详情简短介绍活动内容，时间地点人物介绍',
          time: '2020年10月28日',
          po: '南平市建阳区'
        },
        {
          img: require('../../assets/images/video-stop-bg.jpg'),
          isshow: false,
          tit: '这里是直播标题介绍',
          con: '这里是直播详情简短介绍活动内容，时间地点人物介绍',
          time: '2020年10月28日',
          po: '南平市建阳区'
        }
      ]
    }
  },
  methods: {},
  mounted() {}
}
</script>
<style lang="scss" scoped></style>
